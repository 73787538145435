<template>
  <div>
    <h1>理灵文化内推系统</h1>
<!--    <p>CODE: {{result}}</p>-->
    <p>你好，{{welcomeText}}</p>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "HandleLogin",
  data() {
    return {
      result: "",
      code: "",
      referralId: "",
      welcomeText: "",
    }
  },
  created() {
    this.handleResp()
    this.getLink()
  },
  methods: {
    handleResp() {
      this.code = this.$route.query.code
      this.referralId = this.$route.query.state
      this.result = `code: ${this.code}\nreferral id: ${this.referralId}`
      console.log(this.result)
    },
    async getLink() {
      let reqUrl = `https://oa.covenate.tech/onboard/ir/?code=${this.code}&referral_id=${this.referralId}`
      let linkData = (await axios.post(reqUrl)).data
      console.log(linkData)
      this.welcomeText = `${linkData["data"]["nickname"]} 现在进行跳转！`
      window.location.href = linkData["data"]["url"]
    },
  },
}
</script>

<style scoped>

</style>