import Vue from 'vue'
import VueRouter from "vue-router"
import App from './App.vue'
import InitReferral from "@/views/InitReferral";
import HandleLogin from "@/views/HandleLogin";
import NotFound from "@/views/NotFound";
import Referral from "@/views/Referral";
import axios from "axios";

axios.defaults.baseURL = 'http://oa.covenate.tech'
axios.defaults.timeout = 10 * 1000

Vue.use(VueRouter)

Vue.config.ignoredElements = [
    /^yt-/
]

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/referral',
            component: Referral,
            children: [
                {path: '', component: InitReferral},
                {path: 'init', name: 'InitReferral', component: InitReferral},
                {path: 'handleLogin', name: 'HandleLogin', component: HandleLogin},
            ]
        },
        {path: '*', component: NotFound}
    ]
})

new Vue({
    render: h => h(App),
    router
}).$mount('#app')