<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
  </div>
</template>

<script>
export default {
  name: 'InitReferral',
  data() {
    return {
      msg: "载入中，请稍候...",
    }
  },
  mounted() {
    this.requestAuth()
  },
  methods: {
    requestAuth() {
      let appid = "wx42ef9602e8e19dbf"  // 零号社区
      // let appid = "wx7f37b11d6f16c3f0"
      let redirectUri = encodeURI("https://oa.leanhub.cn/referral/handleLogin/")
      let referralId = this.$route.query.referral_id
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_userinfo&state=${referralId}#wechat_redirect`
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
